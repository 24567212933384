export const articles = [
    {
        id: 1,
        title: "How to Add DNS Records",
        slug: "how-to-add-dns-records",
        content: `
          Adding DNS records is an essential part of managing your domain. DNS records help direct traffic to your website and configure various services associated with your domain. In this tutorial, we will guide you through the steps to add DNS records.
    
          Step 1: Access Your Domain Registrar
          First, log in to your domain registrar's website (e.g., GoDaddy, Namecheap, etc.). Once logged in, navigate to the domain management section.
    
          Step 2: Locate DNS Management
          Find the DNS management or DNS settings option for the domain you want to modify. This section allows you to view and edit your DNS records.
    
          Step 3: Add a New DNS Record
          Look for an option to add a new DNS record. You will typically see a button labeled "Add Record" or "Add DNS Record." Click on it to proceed.
    
          Choose the Record Type
          You will need to select the type of DNS record you want to add. Common types include:
          - A Record: Points your domain to an IP address.
          - AAAA Record: Points your domain to an IPv6 address. This allows HTTPS connections.
          - CNAME Record: Points your domain to another domain name.
          - TXT Record: Allows you to add text information to your domain.
    
          Fill in the Record Details
          After selecting the record type, fill in the required details:
          - Name: The subdomain or domain name (e.g., "www" or "example.com").
          - Value: The IP address or target domain (e.g., "192.0.2.1" for an A record).
          - TTL: Time to Live, which determines how long the record is cached (default is usually fine).
          If you are configuring your DNS records for hosting provided by Daedalus Solutions, refer to the provided records on the 'Add Website' form.
    
          Step 4: Save Your Changes
          Once you have filled in the details, click the "Save" or "Add Record" button to save your changes. It may take some time for the changes to propagate across the internet.
    
          Step 5: Verify Your DNS Records
          After a few minutes, you can verify that your DNS records have been added correctly. You can use online tools like What's My DNS to check the status of your DNS records.
          If you have configured your DNS records for Daedalus Solutions hosting, your DNS records will be verified automatically and your WordPress site will be live after a few minutes.
        `
      },
    {
      id: 2,
      title: "How to Migrate your WordPress Site to A New Host",
      slug: "how-to-migrate-your-wordpress-site-to-a-new-host",
      content: `
      Step 1: Download and Backup Your Files and Database.
      Use a plugin, an FTP client like FileZilla, or SCP(if you have a shell on your server) to download all WordPress files on your current host.

      Example SCP command:
      $ scp username@your-server-ip:/path/to/wordpress-installation/* /local/path/to/save/

      Make sure you retrieve the database file. Plugins usually do this themselves, otherwise you need to use phpMyAdmin or SSH.

      Example SSH Commands: 
      $ ssh user@your-server-ip
      $ mysqldump -u database_username -p database_name > backup.sql
      $ exit
      $ scp username@your-server-ip:/path/to/backup.sql /local/path/to/save/

        Step 2: Setup Your Hosting Plan on Your New Host
        Purchase a hosting plan and add all required DNS records related to your new hosting provider. 

        Step 3: Get a Fresh Installation of Wordpress
        Create a new WordPress installation on your new host.

        Step 4 (Plugin): Install a migration plugin.
        If you do not have SSH access, or otherwise can't access files on your host, then install a WordPress migration plugin such as All-in-One WP Migration or Duplicator. Head to the plugin's page in your WordPress admin panel and find an option to import. Import the Wordpress files you downloaded previously, making sure to keep a copy on your own machine.

        Step 4 (SSH): Upload your files and make configurations manually.
        If you can SSH to your host and do not want to use a plugin or other control panel option, migrating manually is quite easy. 

        Create a zip or tar file of all your WordPress files (including your database backup) and upload them to your host with SCP. Example command:
        $ scp ~/wordpress-files.zip username@your-server-ip:/

        Now SSH into your server:
        $ ssh username@your-server-ip

        Unzip files:
        $ unzip wordpress-files.zip
        $ tar -xzf wordpress-files.tar.gz

        Verify the correct file structure and file contents on your server. Use commands like vim or nano to make adjustments.

        To import a database, log into MySQL:
        $ mysql -u database_username -p
        <mysql> USE database_name;
        <mysql> EXIT;

        Import SQL File:
        $ mysql -u database_username -p database_name < /path/to/backup.sql
      `
    },
  ];