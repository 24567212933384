import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Login from './Login';
import loadingIcon from '../assets/images/transistor_loading_icon.gif'


function AddWebsite() {
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [isValid, setIsValid] = useState(null);
    const [loading, setLoading] = useState(true);
    const [addingWebsite, setAddingWebsite] = useState(false);
    const [user, setUserData] = useState(null);
    const [plan, setPlan] = useState(null);
    const [userHostingPlan, setUserHostingPlanData] = useState(null);
    const [domain, setDomain] = useState('');
    const [subdomain, setSubdomain] = useState(''); // New state for subdomain
    const [website_name, setWebsiteName] = useState('');
    const navigate = useNavigate();
    const [message, setMessage] = useState('');

    useEffect(() => {
        const validateToken = async () => {
            if (token) {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/validate-token`, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                    if (response.status === 200) {
                        setIsValid(true);
                        const userResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/user-data`, {
                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                        });
                        setUserData(userResponse.data);

                        const userPlanResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/user-plan-data`, {
                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                        });
                        setUserHostingPlanData(userPlanResponse.data);

                        if (userPlanResponse.data && userPlanResponse.data.length > 0) {
                            const planResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/hosting_plans/${userPlanResponse.data[0].hosting_plan_id}`, {
                                headers: {
                                    Authorization: `Bearer ${token}`
                                }
                            });
                            setPlan(planResponse.data);
                        } else {
                            console.error("userHostingPlan is undefined or empty");
                        }
                    }
                } catch (error) {
                    console.error("Error validating token or fetching data:", error);
                    setIsValid(false);
                } finally {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        };

        validateToken();
    }, [token]);

    if (loading) {
        return <div className='loading'><p>Loading...<img src={loadingIcon} className="front-page-logo" height={50} /></p></div>;
    }

    if (addingWebsite) {
        return <div className='loading'><p>Your website is being installed. You will receive an email with login credentials momentarily. (Check your spam folder!)<br /><br /> Please wait...<img src={loadingIcon} className="front-page-logo" height={50} /></p></div>
    }

    if (!isValid) {
        localStorage.removeItem('token');
        return <Login setToken={setToken} />;
    }

    const handleDomainInputChange = (e) => {
        setDomain(e.target.value);
    };

    const handleSubdomainInputChange = (e) => {
        setSubdomain(e.target.value); // Update subdomain state
    };

    const handleNameInputChange = (e) => {
        setWebsiteName(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setAddingWebsite(true);
        setMessage('');

        // Determine whether to use domain or subdomain
        const finalDomain = domain.trim() ? domain : `${subdomain}.daedalussolutions.website`;

        if (!domain.trim() && !subdomain.trim()) {
            setMessage('Please enter either a domain or a subdomain.');
            setAddingWebsite(false);
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/add-website`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({ domain: finalDomain, website_name }),
            });

            const data = await response.json();

            if (response.ok) {
                setMessage(`Domain ${finalDomain} added successfully!`);
            } else {
                setMessage(`Error: ${data.message}`);
            }
        } catch (error) {
            setMessage('An error occurred while adding the website.');
        }

        setAddingWebsite(false);
    };

    return (
        <div className="addwebsite-form-bg">
            <div className='addwebsite-form'>
                <div className='instructions'>
                    <h3>1. Get a domain.</h3>
                    <h3>2. Add the following DNS records to your domain.</h3>
                    <table>
                        <thead>
                            <tr>
                                <th>Type</th>
                                <th>Name</th>
                                <th>Value</th>
                                <th>TTL</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>A Record</td>
                                <td>@</td>
                                <td>108.61.148.162</td>
                                <td>3 min</td>
                            </tr>
                            <tr>
                                <td>AAAA Record</td>
                                <td>@</td>
                                <td>2001:19f0:0005:5aca:5400:05ff:fe23:e7b8</td>
                                <td>3 min</td>
                            </tr>
                            <tr>
                                <td>TXT Record</td>
                                <td>@</td>
                                <td>daedalus-solutions-hosting=v</td>
                                <td>3 min</td>
                            </tr>
                        </tbody>
                    </table>
                    <p><a href="/how-to-add-dns-records">Need help adding DNS records?</a></p>
                    <h3>3. Enter your domain name (including extension).</h3>
                    <p>Ex. yourdomain.net, yourdomain.business</p>
                </div>
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        value={domain}
                        onChange={handleDomainInputChange}
                        placeholder="Enter your domain"
                    /><br />
                    <h3>OR</h3>
                    <input
                        type="text"
                        value={subdomain}
                        onChange={handleSubdomainInputChange}
                        placeholder="Enter a subdomain (e.g., mysite)"
                    /><br />
                    {subdomain && (
                        <p>Your full domain will be: <strong>{`${subdomain}.daedalussolutions.website`}</strong></p>
                    )}
                    <h3>4. Enter the name of your website.</h3>
                    <input
                        type="text"
                        value={website_name}
                        onChange={handleNameInputChange}
                        placeholder="Enter your website name"
                        required
                    /><br />
                    <button type="submit" disabled={loading}>
                        {loading ? 'Verifying...' : 'Add Domain'}
                    </button>
                </form>
                {message && <p>{message}</p>}
            </div>
        </div>
    );
}

export default AddWebsite;