import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Login from './Login';
import loadingIcon from '../assets/images/transistor_loading_icon.gif'


function UpdateEmail() {
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [isValid, setIsValid] = useState(null);
    const [loading, setLoading] = useState(true);
    const [newEmail, setNewEmail] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const validateToken = async () => {
            if (token) {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/validate-token`, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                    if (response.status === 200) {
                        setIsValid(true);
                    }
                } catch (error) {
                    setIsValid(false);
                } finally {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        };

        validateToken();
    }, [token]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        setSuccess(null);

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/update-email`, {
                newEmail,
                password: currentPassword
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (response.status === 200) {
                setSuccess('Email updated successfully!');
                setNewEmail('');
                setCurrentPassword('');
                localStorage.setItem('token', response.data.token);
            }
        } catch (error) {
            setError('Failed to update email. Please check your password and try again.');
        }
    };

    if (loading) {
        return <div className='loading'><p>Loading...<img src={loadingIcon} className="front-page-logo" height={50} /></p></div>;
    }

    if (!isValid) {
        localStorage.removeItem('token');
        return <Login setToken={setToken} />;
    }

    return (
        <div className="UpdateEmail">
            <h2>Update Email</h2>
            <div className='login-form'>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label>New Email:</label>
                        <input
                            type="email"
                            value={newEmail}
                            onChange={(e) => setNewEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label>Current Password:</label>
                        <input
                            type="password"
                            value={currentPassword}
                            onChange={(e) => setCurrentPassword(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit">Update Email</button>
                    {error && <p className="error">{error}</p>}
                    {success && <p className="success">{success}</p>}
                </form>
            </div>
        </div>
    );
}

export default UpdateEmail;
