import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import loadingIcon from '../assets/images/transistor_loading_icon.gif';

function Receipt() {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [receipt, setReceipt] = useState(null);
    const [hostingPlan, setHostingPlan] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchReceiptData = async () => {
            try {
                const receiptResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/receipts/${id}`);
                setReceipt(receiptResponse.data); 

                if (receiptResponse.data.hosting_plan_id) {
                    const hostingPlanResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/hosting_plans/${receiptResponse.data.hosting_plan_id}`);
                    setHostingPlan(hostingPlanResponse.data);
                }

                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        fetchReceiptData();
    }, [id]); 

    if (loading) {
        return (
            <div className='loading'>
                <p>Loading...<img src={loadingIcon} className="front-page-logo" height={50} /></p>
            </div>
        );
    }
    if (error) return <p>Error: {error}</p>;
    if (!receipt) return <p>No receipt data found.</p>;

    return (
        <div className="Receipt">
            <div className='receipt-details'>
                <h2>Receipt</h2>
                <p>Receipt ID: {receipt.id}</p>
                <p>User ID: {receipt.user_id}</p>
                <p>Hosting Plan: {hostingPlan.plan_type}</p>
                <p>Months Purchased: {receipt.months}</p>
                <p>Amount: {receipt.amount} CAD</p>
                <p>Payment Method: {receipt.payment_method}</p>
                <p>Purchase Date: {moment(receipt.purchase_date).format('MMMM Do YYYY')}</p>
                <p>Status: {receipt.status}</p>
                {receipt.transaction_id && <p>Transaction ID: {receipt.transaction_id}</p>}

                <p>Thank you!</p>
            </div>
        </div>
    );
}

export default Receipt;