import React from 'react';
import { useParams } from 'react-router-dom';
import { articles } from '../data/articles';

const BlogArticle = () => {
  const { slug } = useParams();
  const article = articles.find(a => a.slug === slug);

  if (!article) {
    return <h2>Article not found</h2>;
  }

  return (
    <div className='Blog'>
      <h1>{article.title}</h1>
      <div className='blog-content'>
          <pre style={{ whiteSpace: 'pre-line'}}>
              <p>{article.content}</p>
          </pre>
      </div>
    </div>
  );
};

export default BlogArticle;